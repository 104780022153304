import { useCallback, useState } from "react";
import styles from "./SimpleUpload.module.scss";
import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import * as utils from "./UploadAsset.utils";
import * as helpers from "utils/helpers";
import { AccountLogo } from "shared/types/salesEnablement";

interface Props {
  logos?: AccountLogo[];
  setLogos?: (logos: AccountLogo[]) => void;
  disabled?: boolean;
}

export const SimpleUpload = ({ disabled, logos = [], setLogos }: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  if (!setLogos) throw new Error("setLogos is required");

  const onLogoChange = useCallback(
    ({
      isAdding,
      updatedFile,
    }: {
      isAdding: boolean;
      updatedFile: AccountLogo;
    }) => {
      const newLogos = isAdding
        ? [...logos, updatedFile]
        : logos?.filter(f => f.uid !== updatedFile.uid);
      setLogos(newLogos);
    },
    [setLogos, logos],
  );

  const onChange: UploadProps["onChange"] = async ({
    file: currentFile,
    fileList: updatedFileList,
  }) => {
    const isAdding = updatedFileList.length > logos.length;
    const file = isAdding
      ? updatedFileList[updatedFileList.length - 1]
      : currentFile;

    if (!file) return;

    const dimensions = isAdding ? await utils.getDimensions(file) : undefined;

    const updatedFile: AccountLogo = isAdding
      ? {
          ...file,
          name: helpers.generateGuidFilename(file.name),
          thumbUrl: await utils.toBase64(file.originFileObj),
          size: file.size,
          type: file.type,
          lastModifiedDate: file.lastModifiedDate,
          dimensions,
        }
      : file;

    onLogoChange({ isAdding, updatedFile });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await utils.getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  return (
    <>
      <Upload
        beforeUpload={() => false}
        listType="picture-card"
        fileList={logos}
        onChange={onChange}
        data-cy={"logos"}
        disabled={disabled}
        onPreview={handlePreview}
      >
        {logos.length < 5 && (
          <div className={styles.uploadButton}>
            <PlusOutlined />
            <div>Upload</div>
          </div>
        )}
      </Upload>
      <Modal
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        visible={previewOpen}
      >
        <img style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
